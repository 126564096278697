import { Auth } from "aws-amplify";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Button, Box, Modal } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import classes from "./AdminChecklistsPage.module.css";
import AuthContext from "../../store/auth-context";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";

const AdminChecklistsPage = () => {
  const [checklists, setChecklists] = useState([]);
  const [alert, setAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [idOfChecklistToDelete, setIdOfChecklistToDelete] = useState("");
  const [nameOfChecklistToDelete, setNameOfChecklistToDelete] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  // Style for modal (should probably move to a separate file later)
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        setIsLoading(true);
        setAlert(false);
        const url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists/all`;
        const config = {
          headers: {
            Authorization: `Bearer ${res.signInUserSession.accessToken.jwtToken}`,
          },
        };
        axios
          .get(url, config)
          .then((response) => {
            setChecklists(response.data.Items);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  }, [alert, authCtx, navigate]);

  const getShortDateString = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toDateString();
  };

  const triggerRender = () => {
    setAlert(true);
  };

  const handleDeleteChecklist = async () => {
    // This case shouldnt happen, because the id is set when the delete button is pressed.
    if (idOfChecklistToDelete === "") {
      console.log("No checklist to delete");
      return;
    } else if (!showModal) {
      console.log("Cannot delete checklist, modal not open");
      return;
    }
    const id = idOfChecklistToDelete;

    try {
      const url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists/${id}`;
      const session = await Auth.currentSession();
      const config = {
        headers: {
          Authorization: `Bearer ${session.accessToken.jwtToken}`,
        },
      };
      axios
        .delete(url, config)
        .then((res) => {
          setShowModal(false);
          setIdOfChecklistToDelete("");
          setNameOfChecklistToDelete("");
          triggerRender();
        })
        .catch((err) => {
          setShowModal(false);
          setIdOfChecklistToDelete("");
          setNameOfChecklistToDelete("");
          handleAxiosError(err);
        });
    } catch (err) {
      handleAuthError(err, navigate, authCtx);
    }
  };

  const handleClickDelete = (id, name) => {
    setIdOfChecklistToDelete(id);
    setNameOfChecklistToDelete(name);
    setShowModal(true);
  };

  const handleClickCancel = () => {
    setShowModal(false);
    setIdOfChecklistToDelete("");
    setNameOfChecklistToDelete("");
  };

  const deleteChecklistModal = (
    <Modal open={showModal}>
      <Box sx={style}>
        <h3>Delete Checklist?</h3>
        <p>Name: {nameOfChecklistToDelete}</p>
        <Button
          variant="outlined"
          size="medium"
          sx={{ "margin-right": "10px" }}
          onClick={handleClickCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="medium"
          onClick={handleDeleteChecklist}
        >
          Confirm
        </Button>
      </Box>
    </Modal>
  );

  // FOR PAGINATION TABLE
  const data = checklists.map((item) => {
    return {
      ...item,
      createdDate: getShortDateString(item.createdDate),
      lastModifiedDate: getShortDateString(item.lastModifiedDate),
    };
  });

  const columnObjects = [
    {
      name: "Name",
      type: "link",
      property: "name",
      baseUrl: "/checklists",
    },
    {
      name: "Created",
      type: "text",
      property: "createdDate",
    },
    {
      name: "Created By",
      type: "text",
      property: "createdBy",
    },
    {
      name: "Last Modified",
      type: "text",
      property: "lastModifiedDate",
    },
    {
      name: "Last Modified By",
      type: "text",
      property: "lastModifiedBy",
    },
    {
      name: "Action",
      type: "action",
      color: "danger",
      onClick: handleClickDelete,
      actionName: "Delete",
      actionParameters: ["id", "name"],
    },
  ];

  const paginationTable = (
    <ErrorBoundary>
      <PaginationTable
        data={data}
        keyProperty="id"
        columns={columnObjects}
        loading={isLoading}
      />
    </ErrorBoundary>
  );

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <p className={classes.title}>Checklists</p>
        <div className={classes.backlink}>
          <NavLink to="/admin">
            <ArrowBackIcon />
            <span>Back</span>
          </NavLink>
        </div>
        {paginationTable}
        {showModal && deleteChecklistModal}
      </div>
    </div>
  );
};

export default AdminChecklistsPage;
