import { useState, useContext, Fragment } from "react";
import { Auth } from "aws-amplify";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";

import Card from "../UI/Card";
import AuthContext from "../../store/auth-context";
import { validateEmail } from "../../validation/formValidation";
import { showError } from "../../utils/notifications";
import classes from "./LoginForm.module.css";
import Footer from "../../layout/Footer/Footer";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  // Start states for hidden login enable.
  const [titleClickCounter, setTitleClickCounter] = useState(0);
  const [subtextClickCounter, setSubtextClickCounter] = useState(0);
  const [timerSet, setTimerSet] = useState(false);
  // End states for hidden login enable.

  const authCtx = useContext(AuthContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  // const loginDisabled = !(process.env.REACT_APP_ENABLE_LOGIN === "true");
  const [loginDisabled, setLoginDisabled] = useState(
    !(process.env.REACT_APP_ENABLE_LOGIN === "true")
  );

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const validateCredentials = () => {
    if (email === "" || password === "") {
      if (email === "") {
        // Check if email is empty
        setEmailError("Cannot be empty");
        setIsEmailError(true);
      } else {
        setIsEmailError(false);
        setEmailError("");
      }

      // Check if password is empty
      if (password === "") {
        setPasswordError("Cannot be empty");
        setIsPasswordError(true);
      } else {
        setIsPasswordError(false);
        setPasswordError("");
      }
      return false;
    } else if (!validateEmail(email)) {
      // Reset password error
      setIsPasswordError(false);
      setPasswordError("");

      setEmailError("Must be a valid email address");
      setIsEmailError(true);
      return false;
    }
    // Reset all validation errors
    setIsEmailError(false);
    setIsPasswordError(false);
    setEmailError("");
    setPasswordError("");
    return true;
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!validateCredentials() || loginDisabled) return;

    // Call cognito login function
    try {
      setIsLoading(true);
      const user = await Auth.signIn(email, password);
      authCtx.login(user);
      setIsLoading(false);

      // Redirect to page use was trying to visit, or home as default
      navigate(state?.path || "/home");
    } catch (err) {
      setIsLoading(false);
      if (err.name === "NotAuthorizedException") {
        showError("Error", "Incorrect email or password.");
      } else if (err.name === "UserNotConfirmedException") {
        // Need to confirm user email
        navigate("/confirm-email", {
          state: { email: email, password: password },
        });
      } else {
        showError("Error", "Authentication error.");
        console.error(err);
      }
    }
  };

  const loginDisabledNotice = (
    <p className={classes.loginDisabled}>Login is currently disabled</p>
  );

  //////////////////////////////////////
  //  START HIDDEN ENABLE LOGIN CODE  //
  //////////////////////////////////////

  const handleClickTitle = () => {
    // Increment counter
    setTitleClickCounter(titleClickCounter + 1);
    // If timer isnt set, then set new timer.
    if (!timerSet) {
      setTimeout(() => {
        resetHiddenLoginState();
      }, 5000);
      setTimerSet(true);
    }
  };

  const handleClickSubtext = () => {
    if (titleClickCounter === 9 && subtextClickCounter === 6) {
      setLoginDisabled(false);
      resetHiddenLoginState();
      return;
    }
    if (timerSet) setSubtextClickCounter(subtextClickCounter + 1);
  };

  const resetHiddenLoginState = () => {
    setTitleClickCounter(0);
    setSubtextClickCounter(0);
    setTimerSet(false);
  };

  ////////////////////////////////////
  //  END HIDDEN ENABLE LOGIN CODE  //
  ////////////////////////////////////

  return (
    <Fragment>
      <div className={classes.content}>
        <div className={classes.inset}>
          <Card>
            <form className={classes["login-form"]}>
              <h1 onClick={handleClickTitle}>Log In</h1>
              {loginDisabled && loginDisabledNotice}
              <TextField
                fullWidth
                type="text"
                size="small"
                margin="dense"
                label="Email"
                value={email}
                onChange={handleEmailChange}
                error={isEmailError}
                helperText={emailError}
              />
              <TextField
                fullWidth
                type="password"
                size="small"
                margin="dense"
                label="Password"
                value={password}
                onChange={handlePasswordChange}
                error={isPasswordError}
                helperText={passwordError}
              />
              <LoadingButton
                type="submit"
                variant="contained"
                color="success"
                disabled={loginDisabled}
                onClick={handleLogin}
                loading={isLoading}
              >
                Log In
              </LoadingButton>
              <div>
                <NavLink to="/password-reset">Forgot your password?</NavLink>
              </div>
              <div className={classes["create-account"]}>
                <label onClick={handleClickSubtext}>
                  Dont have a FlightCheck account?
                </label>
                <NavLink to="/signup">Create one for free</NavLink>
              </div>
            </form>
          </Card>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default LoginForm;
