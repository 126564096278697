import { useState, useEffect, useContext } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import PaginationTable from "../../components/PaginationTable/PaginationTable";
import classes from "./AdminOrdersPage.module.css";
import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import AuthContext from "../../store/auth-context";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";

const AdminOrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        setIsLoading(true);
        const url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/orders`;
        const config = {
          headers: {
            Authorization: `Bearer ${res.signInUserSession.accessToken.jwtToken}`,
          },
        };
        axios
          .get(url, config)
          .then((response) => {
            setOrders(response.data.Items);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  }, [authCtx, navigate]);

  const getShortDateString = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toDateString();
  };

  const data = orders.map((item) => {
    return {
      ...item,
      createdDate: getShortDateString(item.createdDate),
      lastModifiedDate: getShortDateString(item.lastModifiedDate),
    };
  });

  const columnObjects = [
    {
      name: "Order ID",
      type: "link",
      property: "id",
      baseUrl: "/admin/orders",
    },
    {
      name: "Status",
      type: "text",
      property: "status",
    },
    {
      name: "Email",
      type: "text",
      property: "email",
    },
    {
      name: "Cognito User ID",
      type: "text",
      property: "cognito_user_id",
    },
    {
      name: "Stripe Customer ID",
      type: "text",
      property: "stripe_customer_id",
    },
    {
      name: "Created",
      type: "text",
      property: "createdDate",
    },
    {
      name: "Created By",
      type: "text",
      property: "createdBy",
    },
    {
      name: "Last Updated",
      type: "text",
      property: "lastUpdatedDate",
    },
    {
      name: "Last Updated By",
      type: "text",
      property: "lastUpdatedBy",
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <p className={classes.title}>Orders</p>
        <div className={classes.backlink}>
          <NavLink to="/admin">
            <ArrowBackIcon />
            <span>Back</span>
          </NavLink>
        </div>
        <ErrorBoundary>
          <PaginationTable
            columns={columnObjects}
            data={data}
            keyProperty="id"
            loading={isLoading}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default AdminOrdersPage;
