import { NavLink } from "react-router-dom";
import { useContext } from "react";
import ExampleChecklists from "../../components/ExampleChecklists.js/ExampleChecklists";
import CheckIcon from "@mui/icons-material/Check";
import { Button } from "@mui/material";

import Footer from "../../layout/Footer/Footer";
import YoutubePlayer from "../../components/YoutubePlayer/YoutubePlayer";
import AuthContext from "../../store/auth-context";
import classes from "./Home.module.css";
import FeedbackBox from "../../components/FeedbackBox/FeedbackBox";
import HowDidYouHearAboutUsPopup from "../../components/HowDidYouHearAboutUsPopup/HowDidYouHearAboutUsPopup";

const Home = () => {
  const authCtx = useContext(AuthContext);
  const showSignupButton = !authCtx.isLoggedIn;

  return (
    <div className={classes.container}>
      <div className={classes.content} data-color-mode="light">
        <h1 className={classes.title}>Custom Checklists for Pilots</h1>
        <h3 className={classes.subtitle}>
          Easily create custom checklists for all your aircraft
        </h3>
        <div className={classes.perks}>
          <ul>
            <li>
              <CheckIcon color="success" fontSize="large" />
              &nbsp;Access from anywhere
            </li>
            <li>
              <CheckIcon color="success" fontSize="large" />
              &nbsp;Easy to use editor
            </li>
            <li>
              <CheckIcon color="success" fontSize="large" />
              &nbsp;Highly customizable
            </li>
            <li>
              <CheckIcon color="success" fontSize="large" />
              &nbsp;Unlimited checklist downloads
            </li>
            <li>
              <CheckIcon color="success" fontSize="large" />
              &nbsp;Professionally printed or done yourself
            </li>
            <li>
              <CheckIcon color="success" fontSize="large" />
              &nbsp;Letter size (8.5x11) or half letter size pages (5.5x8.5)
            </li>
            <li>
              <CheckIcon color="success" fontSize="large" />
              &nbsp;Cancel anytime
            </li>
          </ul>
        </div>
        {showSignupButton && (
          <NavLink to="/signup" className={classes.signupButton}>
            <Button variant="contained" color="neutral" size="large">
              Sign Up!
            </Button>
          </NavLink>
        )}
        <ExampleChecklists />
        <div className={classes.tutorial}>
          <h3 className={classes.smallerTitle}>See how it works</h3>
          <div className={classes.video}>
            <YoutubePlayer embedId="jyu4oNX12hI" />
          </div>
        </div>
      </div>
      <FeedbackBox />
      <HowDidYouHearAboutUsPopup />
      <Footer />
    </div>
  );
};

export default Home;
