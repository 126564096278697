import { useContext } from "react";
import { Navigate, useLocation } from "react-router";
import AuthContext from "../../store/auth-context";

const NavigationGuard = (props) => {
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  if (props.adminOnly) {
    if (authCtx.isLoggedIn) {
      if (authCtx.isAdmin) {
        return props.children;
      } else {
        return (
          <Navigate to="/home" replace state={{ path: location.pathname }} />
        );
      }
    } else {
      return (
        <Navigate to="/login" replace state={{ path: location.pathname }} />
      );
    }
  } else {
    return authCtx.isLoggedIn ? (
      props.children
    ) : (
      <Navigate to="/login" replace state={{ path: location.pathname }} />
    );
  }
};

export default NavigationGuard;
